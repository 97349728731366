// @file phoenix socket setup
import { getRealtimeUrl } from '@@/bits/brahms_setup'
import { captureException } from '@@/bits/error_tracker'
import * as Phoenix from 'phoenix'

export function getSocket(
  {
    accessToken,
    deviceId,
    shouldUseLongPoll,
  }: { accessToken: string; deviceId?: string | undefined | null; shouldUseLongPoll?: boolean | undefined | null },
  reconnectAfterMs?: ((tries: number) => number) | undefined,
): Phoenix.Socket | undefined {
  try {
    const socket = new Phoenix.Socket(getRealtimeUrl(), {
      params: { accessToken, deviceId },
      reconnectAfterMs,
      longpollerTimeout: 31000,
      transport: shouldUseLongPoll ?? false ? Phoenix.LongPoll : undefined,
    })

    return socket
  } catch (e) {
    captureException(e)
  }

  return undefined
}

// @file Establishes connection with Brahms.
import environment from '@@/bits/environment'
import window from '@@/bits/global'
import { currentHostname } from '@@/bits/location'
import { NATIVE_HOST, ORG_HOST } from '@@/bits/url'
import type { Channel } from 'phoenix'
import { LongPoll, Socket } from 'phoenix'

const MESSAGE_QUEUE: any[] = []
const RECONNECT_DELAY = [50, 75, 100, 200, 500, 1000, 2000, 5000]
let connectionRetries = 0

function getRealtimeDomainName(): string {
  if (environment === 'production') {
    const currentHost = currentHostname()
    if (currentHost.endsWith('.padlet.com')) return NATIVE_HOST
    // For non-native domain, we should use `padlet.org` as the host
    if (currentHost.endsWith('org')) return ORG_HOST
    return currentHost
  }
  // For non-production, we should use the native host for realtime
  return NATIVE_HOST
}

function getRealtimeUrl(): string {
  const protocol = 'wss'
  const realtimePath = '/_/realtime'
  return `${protocol}://${getRealtimeDomainName()}${realtimePath}`
}

const getSocket = (accessToken: string, deviceId: string): Socket | null => {
  try {
    return new Socket(getRealtimeUrl(), {
      params: { accessToken, deviceId },
      longpollerTimeout: 31000,
      reconnectAfterMs: (tries: number) => {
        connectionRetries++
        return RECONNECT_DELAY[tries - 1] || 10000
      },
    })
  } catch (e) {
    return null
  }
}

const subscribeToChannel = (socket: Socket, topic: string): [Channel, number] | [null, null] => {
  try {
    const channel = socket.channel(topic)
    const ref = channel.on('new_msg', (data) => {
      MESSAGE_QUEUE.push(data)
    })
    channel.join()
    return [channel, ref]
  } catch (e) {
    return [null, null]
  }
}

const subscribeToWallBrahmsChannel = (socket: Socket, wallId: string): [Channel, number] | [null, null] => {
  return subscribeToChannel(socket, `wall:${wallId}`)
}

const subscribeToDeviceBrahmsChannel = (socket: Socket, deviceId: string): [Channel, number] | [null, null] => {
  return subscribeToChannel(socket, `device:${deviceId}`)
}

const subscribeToUserBrahmsChannel = (socket: Socket, userId: string): Channel | null => {
  try {
    const userChannel = socket.channel(`user:${userId}`)
    userChannel.join()
    return userChannel
  } catch (e) {
    return null
  }
}

const initializeBrahmsConnection = (): void => {
  const { accessToken, wallId, userId, deviceId } = window.ww.brahmsStartingState
  if (!accessToken || !wallId || !userId || !deviceId) return

  if (!window.ww) window.ww = {}
  if (window.ww.brahms == null) window.ww.brahms = {}

  // Initiate connection with Brahms
  const socket = getSocket(accessToken, deviceId)
  if (socket == null) return
  socket?.onError((_, transport, establishedConnections) => {
    if (transport === WebSocket && establishedConnections === 0 && connectionRetries > 1) {
      socket?.replaceTransport(LongPoll)
      socket?.connect()
      window.ww.brahms = { ...window.ww.brahms, isUsingLongPolling: true }
    }
  })
  socket.connect()

  // Connect to wall channel
  const [wallBrahmsChannel, wallBrahmsChannelMsgRef] = subscribeToWallBrahmsChannel(socket, wallId)
  if (wallBrahmsChannel == null || wallBrahmsChannelMsgRef == null) return

  // Connect to device channel
  const [deviceBrahmsChannel, deviceBrahmsChannelMsgRef] = subscribeToDeviceBrahmsChannel(socket, deviceId)
  if (deviceBrahmsChannel == null || deviceBrahmsChannelMsgRef == null) return

  // Connect to user channel
  const userBrahmsChannel = subscribeToUserBrahmsChannel(socket, userId)
  if (userBrahmsChannel == null) return

  window.ww.brahms = {
    ...window.ww.brahms,
    socket,
    // Phoenix doesn't allow us to get the reference of
    // an already established subscription on a channel, so we need to
    // pass all the references.
    channels: {
      wall: wallBrahmsChannel,
      device: deviceBrahmsChannel,
      user: userBrahmsChannel,
    },
    // We want to remove the listeners defined on this file
    // once we get to the `realtime` store, so we need to
    // pass the handler references.
    msgRefs: {
      wall: wallBrahmsChannelMsgRef,
      device: deviceBrahmsChannelMsgRef,
    },
    // Received messages will not be queued if a handler is
    // not defined, so we pass a message queue that the
    // `realtime` store will clear once other handlers are added.
    messageQueue: MESSAGE_QUEUE,
  }
}
export { getRealtimeUrl }
export default initializeBrahmsConnection
